<template>
  <div v-if="request">
    <!-- {{request}} -->
    <div class="mb-4 row">
      <div class="col">
        <h2 class="pl-5 mb-3 h4">
          Запрос {{ reqType }} {{ request.partnerName }}
        </h2>
        <ul class="pl-5 mr-5 list">
          <li><b>Статус запроса</b>{{ reqStatus }}</li>
          <li><b>Дата</b>{{ reqDate }}</li>
        </ul>
        <div v-if="request.requestType < 2">
          <h2 class="pl-5 mb-3 h4">Производитель</h2>
          <div class="d-flex">
            <div>
              <ul class="pl-5 mr-5 list">
                <li><b>ИНН</b>{{ request.partnerInn }}</li>
                <li><b>Наменование</b>{{ request.partnerName }}</li>
                <li><b>Инициатор</b>{{ request.initiatorName }}</li>
                <li><b>Адрес</b>{{ request.partnerAddress }}</li>
              </ul>
            </div>

            <div>
              <ul class="pl-5 mr-5 list">
                <li><b>Email</b>{{ request.partnerEmail }}</li>
                <li><b>Телефон</b>{{ request.partnerPhone }}</li>
                <li><b>Сайт</b>{{ request.partnerWebSite }}</li>
              </ul>
            </div>
          </div>
        </div>

        <div v-if="request.requestType > 1">
          <h2 class="pl-5 mb-3 h4">Партнер</h2>
          <div class="d-flex">
            <div>
              <ul class="pl-5 mr-5 list">
                <li><b>ИНН</b>{{ request.partnerInn }}</li>
                <li><b>Наменование</b>{{ request.partnerName }}</li>
                <li>
                  <b>Контактное лицо</b>{{ request.partnerContactPerson }}
                </li>
                <li><b>Инициатор</b>{{ request.initiatorName }}</li>
                <li><b>Email</b>{{ request.partnerEmail }}</li>
                <li><b>Телефон</b>{{ request.partnerPhone }}</li>
                <li><b>Сайт</b>{{ request.partnerWebSite }}</li>
              </ul>
            </div>

            <div>
              <ul class="pl-5 mr-5 list">
                <li><b>Адрес</b>{{ request.partnerAddress }}</li>
                <li>
                  <b>Название офиса</b>{{ request.partnerAddressCaption }}
                </li>
                <li>
                  <b>Телефон офиса</b>{{ request.partnerAddressPhone }}
                </li>
                <li>
                  <b>Менеджер офиса</b>{{ request.partnerAddressAccost }}
                </li>
              </ul>
            </div>
          </div>
          <h2 class="pl-5 mb-3 h4">Производитель</h2>
          <ul class="pl-5 mr-5 list">
            <li><b>ИНН</b>{{ request.manufacturerInn }}</li>
            <li><b>Название</b>{{ request.manufacturerName }}</li>
            <li><b>Email</b>{{ request.manufacturerEmail }}</li>
          </ul>
        </div>
      </div>
    </div>

    <approve-reject-form 
      v-if="(request.status == 0 && isManager) && [2,3,4,5].includes(request.requestType)"
      v-model="message"
      :request-type="request.requestType"
      :loading="loading"
      @confirm="onManagerConfirm" 
      @rejectToCorrection="onManagerRejectToCorrection" 
      @reject="onManagerReject"
    />
    <approve-reject-form 
      v-else-if="(request.status == 1 && isLeader) || (request.status == 0 && isLeader && request.approverId == userId) && [2,3,4,5].includes(request.requestType)"  
      v-model="message"
      :request-type="request.requestType"
      :loading="loading"
      @confirm="onLeaderConfirm" 
      @rejectToCorrection="onLeaderRejectToCorrection" 
      @reject="onLeaderReject"
    />

    <div class="row">
      <div class="col">
        <p class="px-5 mb-3 h4">История изменения статусов</p>
        <p>
          <b-table
            ref="selectableTable"                
            class="px-5"
            sticky-header="calc(100vh - 420px)"
            hover
            selectable            
            select-mode="single"
            :items="requestStatuses"
            :fields="requestStatusesFields"
          ></b-table>
        </p>

        <div v-if="!requestStatusesTotal" class="text-center">
          Нет записей
        </div>
      </div>
    </div>

    <b-modal
      ref="modalImpossibleToCorrection"
      title="Запрос на корректировку невозможен"
      centered
      ok-only
      ok-title="Закрыть"
    >
      <p>
        Невозможно отправить запрос на корректировку, так как данный партнер был
        уже ранее подтвержден и существует в базе данных. Примите или Отклоните
        запрос безвозвратно.
      </p>
    </b-modal>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapActions, mapGetters } from "vuex";
import { RequestType, RequestStatus } from "@/constants";
import { DateFormat } from "@/helpers";
import { ApproveRejectForm } from '@/components';

export default {
  name: "RequestDetails",
  components: { 
    ApproveRejectForm 
  },  
  data() {
    return {
      loading: false,
      keyVal: 0,
      requestParams: {
        first: 0,
        rows: 10
      },
      requestStatusesFields: [
        { key: "status", label: "Статус", sortable: false },
        { key: "createBy", label: "Пользователь ", sortable: false },
        { key: "createAt", label: "Дата", sortable: false, formatter: item => DateFormat.getDate(item) },
        { key: "comment", label: "Сообщение", sortable: false }
      ],
      reqType: "",
      reqStatus: "",
      message: "",
      reqDate: "",  
    };
  },
  methods: {
    ...mapActions('requests', [
      "fetchManufacturerById",
      "fetchRequest",
      "fetchRequestStatuses",
      "fetchRequestSelfRetail",
      "editRequestSelfRetail"
    ]),
    confirm(role, type, data) {
      const actions = {
        "leader": [null, null, "confirmLeader", "confirmDealerCertificateByLeader", "leaderApproveAddSelfRetail", "leaderApproveCertificationSelfRetail"],
        "manager": [null, null, "confirmFirstManager", "confirmDealerCertificateByManager", "managerApproveAddSelfRetail", "managerApproveCertificationSelfRetail"]
      }
      this.loading = true
      this.$store.dispatch(`requests/${actions[role][type]}`, data).then(() => {
        this.fetchRequestData();
      })
      .finally(() => this.loading = false);
    },
    rejectToCorrection(role, type, data) {
      const actions = {
        "leader": [null, null, "rejectLeaderForCorrection", null, "leaderRejectForCorrectionAddSelfRetail", null],
        "manager": [null, null, "rejectManagerForCorrection", null, "managerRejectForCorrectionAddSelfRetail", null]
      }
      this.loading = true
      this.$store.dispatch(`requests/${actions[role][type]}`, data).then(() => {
        this.fetchRequestData();
      })
      .finally(() => this.loading = false);
    },
    reject(role, type, data) {
      const actions = {
        "leader": [null, null, "rejectLeader", "rejectDealerCertificateByLeader", "leaderRejectAddSelfRetail", "leaderRejectCertificationSelfRetail"],
        "manager": [null, null, "rejectManager", "rejectDealerCertificateByManager", "managerRejectAddSelfRetail", "managerRejectCertificationSelfRetail"]
      }
      this.loading = true
      this.$store.dispatch(`requests/${actions[role][type]}`, data).then(() => {
        this.fetchRequestData();
      })
      .finally(() => this.loading = false);
    },
    
    onManagerConfirm() {      
      const dataForm = {
        requestId: this.$route.params.id,
        message: this.message
      }
      this.confirm("manager", this.request.requestType, {id: this.request.id, payload: dataForm})
    },
    onManagerReject() {
      const dataForm = {
        requestId: this.$route.params.id,
        message: this.message
      }
      this.reject("manager", this.request.requestType, {id: this.request.id, payload: dataForm})      
    },
    onManagerRejectToCorrection() {
      if (
        this.request.isAddressApproved &&
        this.request.isPartnerApproved &&
        this.request.isUserApproved
      ) {
        this.$refs["modalImpossibleToCorrection"].show();
        return;
      }

      const dataForm = {
        requestId: this.$route.params.id,
        message: this.message
      };
      this.rejectToCorrection("manager", this.request.requestType, {id: this.request.id, payload: dataForm});
    },
    onLeaderConfirm() {
      const dataForm = {
        requestId: this.$route.params.id,
        message: this.message
      };
      this.confirm("leader", this.request.requestType, {id: this.request.id, payload: dataForm});
    },
    onLeaderReject() {
      const dataForm = {
        requestId: this.$route.params.id,
        message: this.message
      };
      this.reject("leader", this.request.requestType, {id: this.request.id, payload: dataForm});
    },
    onLeaderRejectToCorrection() {
      if (
        this.request.isAddressApproved &&
        this.request.isPartnerApproved &&
        this.request.isUserApproved
      ) {
        this.$refs["modalImpossibleToCorrection"].show();
        return;
      }

      const dataForm = {
        requestId: this.$route.params.id,
        message: this.message
      };
      this.rejectToCorrection("leader", this.request.requestType, {id: this.request.id, payload: dataForm});
    },
    
    fetchRequestData() {
      this.fetchRequest(this.$route.params.id).then(() => {
        console.log(
          "this.request.requestType " + this.request.requestType
        );
        this.reqType = RequestType.translate(this.request.requestType);
        this.reqStatus = RequestStatus.translate(this.request.status);
        this.reqDate = DateFormat.getDate(this.request.dateCreated);
        console.log(this.request);
        this.fetchRequestStatuses(this.request.id);
      });
    }
  },
  computed: {
    ...mapGetters([
      "isLeader",
      "isManager"
    ]),
    ...mapState({userId: state => state.auth.user.id}),
    ...mapState('requests', {
      requestStatuses: state => state.requestStatuses,
      requestStatusesTotal: state => state.requestStatusesTotal,
      request: state => state.request,
      manufacturer: state => state.manufacturer
    })
  },
  created() {
    this.fetchRequestData();
  }
};
</script>

<style lang="scss"></style>
